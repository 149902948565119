import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import NewsCard from '../../components/newsCard';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles({
  newsContainer: {
    marginTop: 20,
    padding: '20px 160px 40px 160px',
    '@media (max-width:1024px)': {
      padding: '20px 100px 40px 100px',
    },
    '@media (max-width:900px)': {
      padding: '20px 50px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      marginTop: 0,
    },
  },
});

export default () => {
  const classes = useStyles();
  const newsData = useStaticQuery(query);
  const newsArray = newsData.allContentfulBlogPost.edges;

  return (
    <>
      {/* <SEO title="Vow ASA | News" /> //TODO: TEST SEO */}
      <Container maxWidth="xl" style={{ paddingLeft: 0, paddingRight: 0 }}>
        {/* <h3>Latest News</h3>
        </Container> */}
        <Grid container spacing={4} className={classes.newsContainer}>
          {newsArray.map(({ node }) => (
            <Grid item sm={4} md={4} style={{ marginTop: 20 }}>
              <NewsCard data={node} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

const query = graphql`
  query NewsTabQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(quality: 90, maxWidth: 300, maxHeight: 200) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
