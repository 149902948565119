import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { shape, string } from 'prop-types';
import Img from 'gatsby-image';
// import COLORS from '../constants/colors';
// import FONTS from '../constants/fonts';
import { COLOR_CORAL } from '../utils/constants';
import Youtube from './video/youtube';

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '160px',
    '@media (max-width:600px)': {
      height: '140px',
      marginBottom: 60,
    },
  },
  link: {
    textDecoration: 'none !important',
    color: '#1D1D1D',
  },
  title: {
    marginBottom: 8,
    // fontSize: FONTS.SIZE.S,
    lineHeight: '1.4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
    fontWeight: 'bold',
  },
  description: {
    margin: 0,
    // fontSize: FONTS.SIZE.XXS,
  },
  titleHeight: {
    '@media (max-width:600px)': {
      height: 60,
    },
  },
  line: {
    display: 'block',
    width: 30,
    height: '2px',
    border: 0,
    borderTop: `4px solid${COLOR_CORAL}`,
    margin: '0.5em 0',
    padding: 0,
  },
  video: {
    maxWidth: 650,
    [theme.breakpoints.down('xs')]: {
      width: 350,
    },
  },
}));

const MediaCard = ({ data }) => {
  const videoLink = data.videoLink;
  const thumbnail = data.thumbnail;
  const url = data.document?.file?.url;

  const classes = useStyles();
  return (
    <div>
      {videoLink && (
        <>
          <Grid item xs={12} md={6} className={classes.video}>
            <Youtube
              imgFluid={thumbnail ? thumbnail.fluid : null}
              videoUrl={videoLink}
            />
          </Grid>
          <div className={classes.flexContainer}>
            <div className={classes.titleHeight}>
              <h4 className={classes.title}>{data.title}</h4>
            </div>
            <div>
              <p className={classes.description}>{data.publishDate}</p>
              <hr className={classes.line} />
            </div>
          </div>
        </>
      )}
      {url && (
        <a
          href={`https:${url}`}
          target="_blank"
          rel="noopener noreferrer"
          key={url}
          className={classes.link}
        >
          <Grid className={classes.video} item xs={12} md={6}>
            <Img fluid={data.thumbnail.fluid} />
          </Grid>
          <div className={classes.flexContainer}>
            <div className={classes.titleHeight}>
              <h4 className={classes.title}>{data.title}</h4>
            </div>
            <div>
              <p className={classes.description}>{data.publishDate}</p>
              <hr className={classes.line} />
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

MediaCard.propTypes = {
  data: shape({
    title: string,
    publishDate: string,
    thumbnail: shape({
      fluid: shape(),
    }),
  }).isRequired,
};

export default MediaCard;
