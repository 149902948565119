import React from 'react';
import { string, shape, func } from 'prop-types';
import VideoThumbnail from './videoThumbnail';
import { makeStyles } from '@material-ui/core/styles';
import VideoDialog from './videoDialog';

const useStyles = makeStyles(theme => ({
  defaultImage: {
    position: 'absolute',
    height: '134%',
    width: '101%',
    top: '-17%',
  },
  [theme.breakpoints.down('md')]: { top: '-0%' },
}));

const YOUTUBE_ID_LENGTH = 11;

const getVideoId = videoUrl =>
  videoUrl.split('v=')[1].substring(0, YOUTUBE_ID_LENGTH);

export const getVideoThumbnail = videoUrl =>
  `https://img.youtube.com/vi/${getVideoId(videoUrl)}/sd2.jpg`;

const Youtube = ({ imgFluid, videoUrl, customTriggerElement }) => {
  const classes = useStyles();

  const iframe = props => (
    <iframe
      {...props}
      src={`https://www.youtube.com/embed/${getVideoId(
        videoUrl
      )}?autoplay=1&rel=0`}
      frameBorder="0"
      allow="autoplay; encrypted-media;"
      allowFullScreen
      title="youtube-player"
    ></iframe>
  );

  return (
    <>
      {customTriggerElement ? (
        <VideoDialog
          videoIframe={iframe}
          triggerElement={customTriggerElement}
        />
      ) : (
        <VideoThumbnail
          imgFluid={imgFluid}
          defaultImageLink={getVideoThumbnail(videoUrl)}
          defaultImageClasses={classes.defaultImage}
        >
          {iframe}
        </VideoThumbnail>
      )}
    </>
  );
};

Youtube.propTypes = {
  imgFluid: shape(),
  videoUrl: string.isRequired,
  customTriggerElement: func,
};

Youtube.defaultProps = {
  imgFluid: undefined, //TODO: can be changed to a default youtube video image.
  videoUrl: 'https://www.youtube.com/watch?v=hqxsGuCd4jM', //TODO: must be changed.
};

export default Youtube;
