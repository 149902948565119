import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import rode from '../../images/rode.jpeg';
import { COLOR_CORAL } from '../../utils/constants';
import Button from '../../components/button';

const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    marginTop: 80,
    '@media (max-width:600px)': {
      flexDirection: 'column-reverse',
      marginTop: 40,
    },
  },
  imageGrid: {
    padding: '20px 0px 40px 100px',
    // alignSelf: 'flex-end',
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '0px 0px 20px 0px',
      borderBottom: 'none',
    },
  },
  textGrid: {
    padding: '20px 0px 40px 100px',
    maxWidth: 720,
    '@media (max-width:1024px)': {
      padding: '20px 0px 40px 80px',
    },
    '@media (max-width:900px)': {
      padding: '20px 0px 40px 50px',
    },
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
      borderBottom: 'none',
    },
  },
  link: {
    color: COLOR_CORAL,
    textDecoration: 'none',
  },
});

export default () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item sm={6} className={classes.textGrid}>
          <h3 style={{ marginTop: 0 }}>
            Green success on the Oslo Stock Exchange
          </h3>
          <p>
            Vow ASA is in the process of revolutionizing the cruise industry
            with its new technology, which turns all sewage and organic waste on
            board ships into climate-friendly energy. Listen to the company's
            new podcast, The VOW Pod, here!
          </p>
          <br />
          <p>This podcast is only availible in Norwegian language.</p>
          <Button
            label="Lytt på Spotify"
            marginBottom={20}
            // href="https://open.spotify.com/show/0ubGglQTOqc0dqCEGYS12I"
            href="https://open.spotify.com/show/0ubGglQTOqc0dqCEGYS12I?si=iAuOSUNBSoOB2DSww_IJaA"
          />
          <Button
            label="Lytt på Apple music"
            marginBottom={20}
            marginLeft={40}
            padMarginLeft={10}
            href="https://podcasts.apple.com/no/podcast/the-vow-pod/id1524082441 "
          />
        </Grid>
        <Grid item sm={6} className={classes.imageGrid}>
          <img src={rode} alt="microphone" />
        </Grid>
      </Grid>
    </>
  );
};
