import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Img from 'gatsby-image';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { shape, func, string } from 'prop-types';
import { WHITE } from '../../utils/constants';
import VideoDialog from './videoDialog';

const useStyles = makeStyles({
  VideoThumbnail: {
    backgroundColor: 'transparent',
    position: 'relative',
    cursor: 'pointer',
    color: WHITE,
    '&:hover': {
      '& $playIcon': {
        '& $svg': {
          opacity: 1.0,
        },
      },
    },
  },
  image: {
    '&>div': {
      height: 'inherit',
    },
  },
  playIcon: {
    backgroundColor: 'rgba(248,248,248,0.3)',
    backdropFilter: 'blur(3px)',
    borderRadius: '50%',
    width: '25%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    '&>svg': {
      display: 'block',
      margin: 'auto',
      opacity: 0.7,
      width: '100%',
      height: '100%',
    },
  },
});

const VideoThumbnail = ({
  children,
  imgFluid,
  defaultImageLink,
  defaultImageClasses,
}) => {
  const classes = useStyles();

  return (
    <VideoDialog
      triggerElement={props => (
        <Paper
          className={classes.VideoThumbnail}
          elevation={0}
          square
          {...props}
        >
          {imgFluid ? (
            <Img
              fluid={imgFluid}
              fullWidth={imgFluid}
              image={imgFluid}
              className={classes.image}
              draggable={false}
            />
          ) : (
            <img
              src={defaultImageLink}
              className={`${classes.image} ${defaultImageClasses}`}
              alt="video thumbnail"
            />
          )}
          <div className={classes.playIcon}>
            <PlayArrowIcon />
          </div>
        </Paper>
      )}
      videoIframe={iframeProps => children(iframeProps)}
    ></VideoDialog>
  );
};

VideoThumbnail.propTypes = {
  children: func.isRequired,
  imgFluid: shape(),
  defaultImageLink: string,
  defaultImageClasses: string,
};

VideoThumbnail.defaultProps = {
  imgFluid: undefined, //TODO: can be changed to a default image.
  defaultImageClasses: '',
};

export default VideoThumbnail;
